<template>
    <div class="cominforOperator flex">
        <div class="inforList flex flexColumn spacebetween " style="margin-left:25px;">
            <span>2018门诊急诊总量</span>
            <div class="inforListInput">
                <input type="text" v-model="org.hospital_business.emergency" placeholder="请输入">
                /人次
            </div>
        </div>
        <div class="inforList flex flexColumn spacebetween ">
            <span>2018手术量</span>
            <div class="inforListInput">
                <input type="text" v-model="org.hospital_business.operation" placeholder="请输入">
                /人次
            </div>
        </div>
        <div class="inforList flex flexColumn spacebetween ">
            <span>2018出院人次</span>
            <div class="inforListInput">
                <input type="text" v-model="org.hospital_business.leave" placeholder="请输入">
                /人次
            </div>
        </div>
        <div class="inforList flex flexColumn spacebetween ">
            <span>2018业务总收入</span>
            <div class="inforListInput">
                <input type="text" v-model="org.hospital_business.income" placeholder="请输入" style="width:94%;">
                <!-- /人次 -->
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>
<script>
export default {
    name:'cominforOperator',
    props:{
        org:{
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            orgs:{},
        }
    },
    created(){
        this.orgs = this.org;
    },
    computed:{
        onorg(){
            const { orgs }  = this;
            return JSON.parse(JSON.stringify({orgs}))
        }
    },
    watch:{
        onorg:{
            handler(news,old) {
                console.log(old)
                if (!old.orgs.id) return false;
                this.$emit('is-button-three',{isbutton:true,unber:3});
            },
            deep: true     
        },
    },
}
</script>
<style lang="scss" scoped>
.inforListInput{
    width: 190px;
    height: 46px;
    background: #ffffff;
    border: 1px solid #dddddd;
    font-size: 16px;
    color: #909191;
    input{
        height: 46px;
        width: 90px;
        font-size: 16px;
        color: #909191;
    }
}
.cominforOperator{
    padding-bottom: 37px;
    width: 100%;
    .inforList{
        color: #212223;
        font-size: 15px;
        align-items: start;
        height: 80px;
        margin-left: 16px;
        margin-right: 16px;
    }
}
</style>
<template>
    <div class="cominforOrgDean">
        <table>
            <tr>
                <td style="width:110px;text-align:left;">姓名</td>
                <td style="width:287px;">
                    <el-input v-model="org.president.name" placeholder="请填写姓名"></el-input>
                </td>
                <td style="width:110px;text-align:left;">固话</td>
                <td style="width:287px;">
                    <el-input v-model="org.president.tel" placeholder="请填写固话"></el-input>
                </td>
            </tr>
            <tr>
                <td style="width:110px;text-align:left;">手机号</td>
                <td style="width:287px;">
                    <el-input v-model="org.president.phone" placeholder="请填写手机号"></el-input>
                </td>
                <td style="width:110px;text-align:left;">邮箱</td>
                <td style="width:287px;">
                    <el-input v-model="org.president.email" placeholder="请填写邮箱"></el-input>
                </td>
            </tr>
            <tr>
                <!-- <td style="width:110px;text-align:left;">医院详细地址</td>
                <td style="width:287px;">
                    <el-input v-model="org.hospital.address" placeholder="请填写医院详细地址"></el-input>
                </td> -->
                <td style="width:110px;text-align:left;">创建时间</td>
                <td style="width:287px;">
                    <el-date-picker
                    style="width:280px;"
                    v-model="org.hospital_info.create_time"
                    type="date"
                    placeholder="请选择医院创建时间"
					format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd">
                    <!-- format="yyyy 年 MM 月 dd 日" -->
                    </el-date-picker>
                </td>
            </tr>
            <tr>
                <td style="width:110px;text-align:left;">机构网址</td>
                <td style="width:287px;">
                    <el-input v-model="org.hospital_info.url" placeholder="请填写机构网址"></el-input>
                </td>
                <td style="width:110px;text-align:left;">核定床位数</td>
                <td style="width:287px;">
                    <el-input v-model="org.hospital_info.bed_check" placeholder="请填写核定床位数"></el-input>
                </td>
            </tr>
            <tr>
                <td style="width:110px;text-align:left;">实际开放床位</td>
                <td style="width:287px;">
                    <el-input v-model="org.hospital_info.bed_real" placeholder="请填写实际开放床位"></el-input>
                </td>
                <td style="width:110px;text-align:left;">总建筑面积</td>
                <td style="width:287px;">
                    <el-input v-model="org.hospital_info.area" placeholder="请填写总建筑面积"></el-input>
                </td>
            </tr>
            <tr>
                <td style="width:110px;text-align:left;">诊疗科目</td>
                <td style="width:287px;">
                    <el-input v-model="org.hospital_info.subject" placeholder="请填写诊疗科目"></el-input>
                </td>
                <td style="width:110px;text-align:left;">重点学科</td>
                <td style="width:287px;">
                    <el-input v-model="org.hospital_info.subject_key" placeholder="请填写重点学科"></el-input>
                </td>
            </tr>
        </table>
        <table>
            <tr>
                <td style="width:110px;text-align:left;">是否医保定点单位</td>
                <td style="width:287px;">
                    <el-select v-model="org.hospital_info.is_medical_insurance" placeholder="请选择是否医保定点单位" style="width:287px;">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td style="width:160px;text-align:left;">医保定点协议</td>
                <td style="width:287px;">
                    <el-select v-model="org.hospital_info.medical_insurance" placeholder="请选择医保定点协议" style="width:287px;">
                        <el-option
                        v-for="item in cominfor.pointagreement"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td style="width:160px;text-align:left;">是否DRG试点城市</td>
                <td style="width:287px;">
                    <el-select v-model="org.hospital_info.is_test_city" placeholder="请选择是否DRG试点城市" style="width:287px;">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td style="width:160px;text-align:left;">是否DRG试点医院</td>
                <td style="width:287px;">
                    <el-select v-model="org.hospital_info.is_test_hospital" placeholder="请选择是否DRG试点医院" style="width:287px;">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td style="width:160px;text-align:left;">是否能提供卫统4-1数据</td>
                <td style="width:287px;">
                    <el-select v-model="org.hospital_info.is_supply" placeholder="请选择是否能提供卫统4-1数据" style="width:287px;">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </td>
            </tr>
        </table>
        <div class="" style="width:870px;margin-top:25px;">
            <div class="flex spacebetween " style="width:700px;">
                <div style="width:110px;text-align:left;align-items:start" class="flex alignCenter">医院营业执照</div>
                <div style="width:610px;align-items:flex-end;" class="flex  ">
                    <div v-if="org.hospital_info.business" style="width:148px;height:148px;position:relative;" class="upPicOperParent">
                        <div class="upPicOper flex alignCenter spacearound" style="width:148px;height:148px;position:absolute;top:0;left:0;">
                            <i class="el-icon-delete cursorP" @click="deleteUpPic('business')"></i>
                        </div>
                        <img :src="$store.getters.Baseurl + org.hospital_info.business" style="width:100%;height:100%;" alt="">
                    </div>
                    <div v-else>
                    <common-upload @received="received" :upNumber="1" :recId="'org.hospital_info.business'"></common-upload>
                    <span style="padding-left:15px;">请上传小于2MB的图片</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="" style="width:870px;margin-top:25px;">
            <div class="flex spacebetween " style="width:700px;">
                <div style="width:110px;text-align:left;align-items:start" class="flex alignCenter">医院正门及主楼照片</div>
                <div style="width:610px;align-items:flex-end;" class="">
                    <div style="">
                        <div style="width:148px;height:148px;position:relative;" class="upPicOperParent" v-for="(im,ix) in org.hospital_info.photo" :key="ix">

                            <div class="upPicOper flex alignCenter spacearound" style="width:148px;height:148px;position:absolute;top:0;left:0;">
                                <i class="el-icon-delete cursorP" @click="deleteUpPic(ix)"></i>
                            </div>
                            <img :src="$store.getters.Baseurl + im" style="width:100%;height:100%;" alt="">

                        </div>
                    </div>
                    <div>
                        <common-upload ref="potato" @received="received" :upNumber="9999" :datas="org.hospital_info.photo" :recId="'org.hospital_info.photo'"></common-upload>
                        <span style="padding-left:15px;">请上传小于2MB的图片</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import commonUpload from '@/components/commonUpload'
export default {
    name: 'cominforOrgDean',
    components:{commonUpload},
    props:{
        org:{
            type: Object,
            default: () => {}
        },
        cominfor:{
            type: Object,
            default: () => {}
        },
    },
    data(){
        return{
            options: [
                {
                    value: '是',
                    label: '是'
                }, 
                {
                    value: '否',
                    label: '否'
                }
            ],
        }
    },
    computed:{
        business(){
            return this.org.hospital_info.business
        },
        photo(){
            return this.org.hospital_info.photo
        }
    },
    watch:{
        business(){
            if(this.business){
                this.$message.success('医院营业执照上传成功，点击底部保存后生效！')
            }
        },
        photo(n,o){
            // if()
        }
    },
    methods:{
        received(value, field){
            console.log(value, field)
            if(field == 'org.hospital_info.business'){
                this.org.hospital_info.business = value.join(',')
                this.$forceUpdate()
            }
            if(field == 'org.hospital_info.photo'){
                this.org.hospital_info.photo = value
                this.$forceUpdate()
            }
            this.$forceUpdate()
            // this.org.license = value
        },
        deleteUpPic(ix){
            this.$confirm('确认删除此图片？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if(ix == 'business'){
                    this.org.hospital_info.business = ''
                }else{
                    this.org.hospital_info.photo.splice(ix,1)
                }
                // this.$refs.potato.deletePic(ix)
                this.$forceUpdate()
                this.$message({
                    type: 'success',
                    message: '图片已删除，点击底部保存后生效!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .upPicOperParent{
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        border: 1px dashed #333;
        .upPicOper{
            display: none;
            z-index: 9;
            color: #ffffff;
            background: rgba(0,0,0,0.6);
            display: none;
        }

        transition: all 3s;
    }
.upPicOperParent:hover .upPicOper{
        display: flex;
        transition: all .3s;
    }
.cominforOrgDean{

}
table{
    margin-top: 30px;
    tr{
        height: 66px;
        td{
            font-size: 15px;
            color: #393939;
            &:nth-child(3){
                display: inline-block;
                margin-left: 67px;
                line-height: 66px;
            }
        }
    }
}
</style>
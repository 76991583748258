<template>
    <div class="myInfo">

        <div class="marginAuto" style="width:870px;margin-top:25px;">
            <div class="flex spacebetween " style="width:700px;">
                <div style="width:90px;text-align:left;align-items:start" class="flex">当前头像</div>
                <div style="width:610px;align-items:flex-end;" class="flex ">
                    <img :src="userInfo.portrait" class="myPortrait cursorP" alt="">
                    <el-upload
                    style="margin-left:16px;height:20px;"
                    :action="$store.getters.requestBaseUrl + 'Home/update_img'"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                    <div class="flex editwithportrait cursorP" style="">
                        <img src="@/assets/img/personal/editwithportrait.png" style="width:17px;height:17px;margin-right:8px;" alt="">
                        修改头像
                    </div>
                    </el-upload>
                </div>
            </div>
        </div>

        <div v-if="userInfo.capacity == 3" class="flex spacearound flexColumn alignCenter">
            <div class="rightTitle c33 fontW">联系人信息</div>
            <cominfor-org-person @is-button-one='isButton' ref="orgPerson" :edit='true' :cominfor="comInfor" :org="userInfo"></cominfor-org-person>
            <button v-if="buttonObj.buttonOne" class="submitPersonal flex spacearound alignCenter cursorP" @click="submitAll(1)">提交</button>
            <!-- <div class="" style="width:870px;margin-top:15px;">
                <div class="flex spacebetween " style="width:700px;">
                    <div style="width:110px;text-align:left;" class="flex alignCenter">选择地区</div>
                    <div class="" style="width:609px;">
                        <address-select :provice="'org'" ref="orgAddress" @proviceSE="proviceSE" ></address-select>
                    </div>
                </div>
            </div>
			<div class="" style="width:870px;margin-top:25px;">
			    <div class="flex spacebetween " style="width:700px;">
			        <div style="width:110px;text-align:left;" class="flex alignCenter">详细地址</div>
			        <div class="" style="width: 600px;text-align: left;">
						v-model="org.email"
			            <el-input style="width: 307px;" v-model="userInfo.address" placeholder="请填写详细地址"></el-input>
			        </div>
			    </div>
			</div> -->
            <div class="rightTitle c33 fontW">医院信息</div>
            <cominfor-org @is-button-tow='isButton' ref="org" :edit="true" :cominfor="comInfor" :org="userInfo"></cominfor-org>
            <button v-if="buttonObj.buttonTow" class="submitPersonal flex spacearound alignCenter cursorP" @click="submitAll(2)">提交</button>
            <div class="rightTitle c33 fontW">院长信息</div>
            <cominfor-org-dean ref="orgDean" :org="userInfo" :cominfor="comInfor"></cominfor-org-dean>
            <!-- <button class="submitPersonal flex spacearound alignCenter cursorP" @click="submitAll">提交</button> -->
            <div class="rightTitle c33 fontW" style="border:none;">医院运营信息</div>
            <cominfor-operator @is-button-three='isButton' ref="orgOperator" :org="userInfo"></cominfor-operator>
            <!-- <button class="submitPersonal flex spacearound alignCenter cursorP" @click="submitAll">提交</button>     -->
        </div>
        <div :style="userInfo.capacity == 3?'background-color:#fff;':''" style="padding-bottom:20px;">
            <cominfor-personal v-if="userInfo.capacity != 3" ref="personal" :cominfor="comInfor" :personal="userInfo"></cominfor-personal>
            <my-like @is-button-three='isButton' ref="myLike" :MyLike="userInfo.type_id" :personal="1"></my-like>
            <button v-if="buttonObj.buttonThree" class="submitPersonal flex spacearound alignCenter cursorP" @click="submitAll(3)">提交</button>
        </div>
        <button v-if="userInfo.capacity != 3" class="submitPersonal flex spacearound alignCenter cursorP" @click="submitAll">提交</button>
    </div>
</template>
<script>
import cominforPersonal from '@/components/cominforPersonal'
import addressSelect from '@/components/addressSelectup'
import cominforOrg from '@/components/cominforOrg'
import cominforOrgPerson from '@/components/cominforOrgPerson'
import cominforOrgDean from '@/components/cominforOrgDean'
import cominforOperator from '@/components/cominforOperator'
import myLike from '@/components/myLike'
import { edit_user, edit_user_lecturer, OrgEditUser } from '@/utils/Api/userList'
export default {
    name: 'myInfo',
    components:{
        cominforPersonal,
        myLike,
        cominforOrg,
        cominforOrgPerson,
        cominforOrgDean,
        cominforOperator,
        addressSelect
    },
    data(){
        return{
            parentData:{},
            showTitle:'',
            // 新增提交按钮出发后隐藏
            buttonObj:{
                buttonOne:false,
                buttonTow:false,
                buttonThree:false
            }
        }
    },
    computed:{
        userInfo(){
            if(this.$store.getters.personal.province_id || this.$store.getters.personal.province_id == 0){
                this.chilfrenAssign()
            }
            return this.$store.getters.personal
        },
        comInfor(){
            return this.$store.getters.cominfor
        }
    },
    created(){},
    mounted(){
       /*  this.$nextTick(()=>{
            if(this.$refs.orgAddress){
                this.$refs.orgAddress.assignaddressbinds([this.userInfo.province_id, this.userInfo.city_id, this.userInfo.zone_id])
            }
        }) */
    },
    methods:{
        isButton(data){
            // console.log(data,'-----------');
            if (data.unber == 1) {
                this.buttonObj.buttonOne = data.isbutton;
            }else if (data.unber == 2) {
                this.buttonObj.buttonTow = data.isbutton;
            }
            this.buttonObj.buttonThree = data.isbutton;
        },
        chilfrenAssign(){
			// console.log('===========================================================')
            this.$nextTick(()=>{
                if(this.$refs.personal){
                    this.$refs.personal.assignaddressbinds([this.userInfo.province_id, this.userInfo.city_id, this.userInfo.zone_id])
                }
                if(this.$refs.orgAddress){
                    this.$refs.orgAddress.assignaddressbinds([this.userInfo.province_id, this.userInfo.city_id, this.userInfo.zone_id])
                }
            })
        },
        childrenData(){
            let arr = []
            arr[0] = this.userInfo.province_id
            arr[1] = this.userInfo.city_id
            arr[2] = this.userInfo.zone_id
            return arr
        },
        proviceSE(value, field){
            if(field == 'org'){
                this.userInfo.province_id = value[0]
                this.userInfo.city_id = value[1]
                this.userInfo.zone_id = value[2]
                return
            }
        },
        async submitAll(iSbutton){
            if(iSbutton==1) this.buttonObj.buttonOne = false;
            if(iSbutton==2) this.buttonObj.buttonTow = false;
            if(iSbutton==3) this.buttonObj.buttonThree = false;
            let commonData, params, mylike
            if(this.userInfo.capacity == 1){
                mylike = this.$refs.myLike.MyLike
                commonData = this.$refs.personal.personal
                if(!commonData.province_id || !commonData.city_id || !commonData.zone_id || !commonData.address){
                    this.$message.warning('请检查个人信息中的地址是否填写完整！')
                    return
                }
                /* if(!(/^[1]([3-9])[0-9]{9}$/).test(commonData.mobile)){
                    this.$message.warning('请正确填写联系电话')
                    return
                } */
                if(!(/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/).test(commonData.email)){
                    this.$message.warning('请正确填写邮箱')
                    return
                }
                // if(!(/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/).test(commonData.id_card)){
                //     this.$message.warning('请正确填写身份证号码')
                //     return
                // }

                if(await edit_user({...commonData, type_ids: mylike.join(','), portrait: '/upload' + commonData.portrait.split('upload')[1]})){
                    this.$message.success('信息修改成功')
                    if(this.$route.query.redirect){
                        this.$router.replace({path: this.$route.query.redirect})
                    }
                    return
                }
            }

            if(this.userInfo.capacity == 2){
                mylike = this.$refs.myLike.MyLike
                params = {
                    ...this.$refs.personal.personal,
                    ...this.$refs.personal.personal.lecturer_info,
                    type_ids: mylike.join(','),
                    portrait: '/upload' + this.$refs.personal.personal.portrait.split('upload')[1]
                }
                if(!params.province_id || !params.city_id || !params.zone_id || !params.address){
                    this.$message.warning('请检查个人信息中的地址是否填写完整！')
                    return
                }
                /* if(!(/^[1]([3-9])[0-9]{9}$/).test(params.mobile)){
                    this.$message.warning('请正确填写联系电话')
                    return
                } */
                if(!(/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/).test(params.email)){
                    this.$message.warning('请正确填写邮箱')
                    return
                }
                if(!(/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/).test(params.id_card)){
                    this.$message.warning('请正确填写身份证号码')
                    return
                }
                
                if(await edit_user_lecturer(params)){
                    this.$message.success('信息修改成功')
                    this.$store.getters.personal.portrait = this.$store.getters.Baseurl + params.portrait
                    if(this.$route.query.redirect){
                        this.$router.replace({path: this.$route.query.redirect})
                    }
                    return
                }
            }



            if(this.userInfo.capacity == 3){
                mylike = this.$refs.myLike.MyLike
                let jg = {
                    /* 公共参数 */
                    org: {
                        real_name: this.$refs.orgPerson.org.real_name,
                        unit: this.$refs.orgPerson.org.unit,
                        mobile: this.$refs.orgPerson.org.mobile,
                        email: this.$refs.orgPerson.org.email,
                        branch: this.$refs.orgPerson.org.branch,
                        offices: this.$refs.orgPerson.org.offices,
                        job: this.$refs.orgPerson.org.job,
                        professional: this.$refs.orgPerson.org.professional,
                    },
                    /* 医院信息 */
                    hospital:{
                        ...this.$refs.org.org.hospital,
                        type_ids: mylike.join(',')
                    },
                    /* 后端 两个map对象中的字段一样，不得不拆开 */
                    address:{
                        province_id: this.$refs.orgPerson.org.province_id,
                        city_id: this.$refs.orgPerson.org.city_id,
                        zone_id: this.$refs.orgPerson.org.zone_id,
                        hos_province_id: this.$refs.org.org.hos_province_id,
                        hos_city_id: this.$refs.org.org.hos_city_id,
                        hos_zone_id: this.$refs.org.org.hos_zone_id,
                        hos_address: this.$refs.orgDean.org.hospital.address
                    },
                    hospital_info:{
                        name: this.$refs.orgDean.org.president.name,
                        pre_tel: this.$refs.orgDean.org.president.tel,
                        phone: this.$refs.orgDean.org.president.phone,
                        pre_email: this.$refs.orgDean.org.president.email,
                        ...this.$refs.orgDean.org.hospital_info,
                        business: this.$refs.orgDean.org.hospital_info.business,
                        photo: this.$refs.orgDean.org.hospital_info.photo && this.$refs.orgDean.org.hospital_info.photo.length > 0 ? this.$refs.orgDean.org.hospital_info.photo.join(',') : '',
                    },
                    hospital_business:{
                        emergency: this.$refs.orgOperator.org.hospital_business.emergency,
                        operation: this.$refs.orgOperator.org.hospital_business.operation,
                        leave: this.$refs.orgOperator.org.hospital_business.leave,
                        income: this.$refs.orgOperator.org.hospital_business.income,
                    }
                }
                params = {
                    ...jg.hospital_business,
                    ...jg.org,
                    ...jg.hospital,
                    ...jg.hospital_info,
                    ...jg.address,
					address: this.userInfo.address,
                    /* 头像 */
                    portrait: '/upload' + this.$refs.orgPerson.org.portrait.split('upload')[1]
                }
              /*  if(!params.province_id || !params.city_id || !params.zone_id){
                    this.$message.warning('请检查个人信息中的地址是否填写完整！')
                    return
                } */
                /* if(!(/^[1]([3-9])[0-9]{9}$/).test(params.mobile)){
                    this.$message.warning('请正确填写联系电话')
                    return
                } */
                if(!(/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/).test(params.email)){
                    this.$message.warning('请正确填写邮箱')
                    return
                }
                /* if(!(/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/).test(params.id_card)){
                    this.$message.warning('请正确填写身份证号码')
                    return
                } */




                if(await OrgEditUser(params)){
                    this.$message.success('信息修改成功')
                    if(this.$route.query.redirect){
                        this.$router.replace({path: this.$route.query.redirect})
                    }
                    // this.$store.getters.personal.portrait = this.$store.getters.Baseurl + value.portrait
                    return
                }
            }
            this.$message.error('信息修改失败')

        },
        handleAvatarSuccess(res, file) {
            this.$store.getters.personal.portrait = this.$store.getters.Baseurl + res.data.url
            return
            this.editUser({portrait: res.data.url})
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        async editUser(value){
            if(await edit_user(value)){
                this.$message.success('信息修改成功')
                this.$store.getters.personal.portrait = this.$store.getters.Baseurl + value.portrait
                if(this.$route.query.redirect){
                        this.$router.replace({path: this.$route.query.redirect})
                    }
                return
            }            
            this.$message.error('信息修改失败')
        }
    },
    watch:{
        userInfo(){
            Object.keys(this.userInfo).forEach((im, ix) => {
                // console.log(im,ix)
                // console.log(this.userInfo[ix])
                // this.parentData[im]
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.submitPersonal{
    width: 79px;
    height: 33px;
    background: #FF9500;
    border-radius: 2px;
    color: #fff;
    font-size: 15px;
    margin: 0 auto;
    // margin-left: 157px;
    margin-top: 20px;
}
.myPortrait{
    width: 88px;
    height: 88px;
    border-radius: 100px;
}
.editwithportrait{
    font-size: 13px;
    color: #FF2B2B;
}
.myInfo{
    padding-bottom: 28px;
}
.rightTitle{
    height: 80px;
    line-height: 80px;
    text-align: left;
    width: 94%;
    margin: auto;
    border-bottom: 1px solid #F2F2F2;
    padding-left: 12px;
    position: relative;
    &::after{
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 2px;
        height: 18px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background:#47d7e3
    }
}
</style>